import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import FirebaseWrapper, {firebaseWrapperContext} from './FirebaseWrapper';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

const app = (
    <ErrorBoundary>
        <firebaseWrapperContext.Provider value={new FirebaseWrapper()}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </firebaseWrapperContext.Provider>
    </ErrorBoundary>
);

ReactDOM.render(app, document.getElementById('root'));
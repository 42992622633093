import React, {memo} from 'react';
import styles from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';

const Modal = (props) => {
    return (
        <>
            <Backdrop isOpen={props.isOpen} onClick={props.onBackdropClick}/>
            <div
                className={styles.Modal}
                style={{
                    transform: props.isOpen ? 'translateY(0)' : 'translateY(-100vh)',
                    opacity: props.isOpen ? '1' : '0'
                }}>
                {props.children}
            </div>
        </>
    );
};

const areEqual = (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen;

export default memo(Modal, areEqual);
import React, {Component} from 'react';
import Modal from '../UIUtils/Modal/Modal';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
		return { hasError: true };
    }
  
    componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info);
    }
  
    render() {
		if (this.state.hasError) {
			return <Modal isOpen><h3>Error</h3><p>Por favor <a href={'/'}>recargue la página</a></p></Modal>;
		}
		return this.props.children; 
    }
}

export default ErrorBoundary;
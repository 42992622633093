export function historyPush(newPath, props) {
    props.history.push({
        pathname: newPath,
        state: {
            prevPath: props.location.pathname
        }
    });
}

export function historyGoBack(props) {
    if (props.location.state && ['/iniciar-sesion', '/'].includes(props.location.state.prevPath)) {
        historyPush("/cerrar-sesion", props);
    }
    else {
        props.history.goBack();
    }
}

export function historyReplace(newPath, props) {
    props.history.replace({
        pathname: newPath,
        state: {
            prevPath: props.location.state ? props.location.state.prevPath : null
        }
    });
}
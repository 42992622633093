import {useEffect, useContext} from 'react';
import {firebaseWrapperContext} from '../FirebaseWrapper';

const useUpdateDbUserLastPageRefreshOrLoggedInTime = (user) => {
    const firebaseWrapper = useContext(firebaseWrapperContext);

    useEffect(() => {
        async function updateDbUserLastPageRefreshOrLoggedInTime() {
            console.log('Running updateDbUserLastPageRefreshOrLoggedInTime()');
            const db = firebaseWrapper.db;
            if (!user || !user.uid) {
                console.log('User is not logged in');
                return;
            }
            try {
                await db.collection("users").doc(user.uid).update({
                    lastPageRefreshOrLoggedInTime: firebaseWrapper.firebase.firestore.FieldValue.serverTimestamp()
                });
                console.log('lastPageRefreshOrLoggedInTime updated');
            } catch (error) {
                console.log('Error updating user lastPageRefreshOrLoggedInTime');
            }
        };
        updateDbUserLastPageRefreshOrLoggedInTime();
    }, [user]);
};

export default useUpdateDbUserLastPageRefreshOrLoggedInTime;
import firebase from "firebase/app";
import 'firebase/auth';
import "firebase/firestore";
import "firebase/functions";
import {result as firebaseConfig} from '../firebase-config.json';

class FirebaseWrapper {
    constructor() {
        firebase.initializeApp(firebaseConfig);
        console.log("env:", process.env.NODE_ENV);
        if (process.env.NODE_ENV === "development") {firebase.functions().useFunctionsEmulator('http://localhost:5000')}
        this.firebase = firebase;
        this.db = firebase.firestore();
        firebase.firestore().enablePersistence().catch(function(err) {
            if (err.code === 'failed-precondition') {
                console.log('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
            } else if (err.code === 'unimplemented') {
                console.log('The current browser does not support all of the features required to enable persistence');
            }
        });
        this.auth = firebase.auth();
        this.functions = firebase.functions();
    }
}

export default FirebaseWrapper;
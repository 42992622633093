import {useState, useEffect, useContext} from 'react';
import {firebaseWrapperContext} from '.';

const useAuth = () => {
    const firebaseWrapper = useContext(firebaseWrapperContext);
    const [state, setState] = useState(() => {
        const user = firebaseWrapper.auth.currentUser;
        return {initializing: !user, user};
    });
    
    const onChange = (user) => {
        setState({initializing: false, user});
    };
    
    useEffect(() => {
        const unsubscribe = firebaseWrapper.auth.onAuthStateChanged(onChange);
        return () => unsubscribe();
    }, []);
    
    return state;
};

export default useAuth;
import React, {useEffect, useState} from 'react';
// import Layout from './containers/Layout/Layout';
// import StepsContainer from './containers/StepsContainer/StepsContainer';
import cssScrollSnapPolyfill from 'css-scroll-snap-polyfill';
import SimpleSnackbar from './components/UIUtils/SimpleSnackbar/SimpleSnackbar';
// import * as serviceWorker from './serviceWorker';
// import {Route, Switch} from 'react-router-dom';
// import Login from './components/Login/Login';
// import Logout from './components/Logout/Logout';
// import RegisterAdditionalUserDataContainer from './containers/RegisterAdditionalUserData/RegisterAdditionalUserDataContainer';
// import Home from './containers/Home/Home';
import {useAuth} from './FirebaseWrapper';
import userContext from './contexts/userContext';
// import Spinner from './components/UIUtils/Spinner/Spinner';
import {withRouter} from "react-router";
import useUpdateDbUserLastPageRefreshOrLoggedInTime from './customHooks/useUpdateDbUserLastPageRefreshOrLoggedInTime';
import {historyPush} from './utils/routing';
import infography from './assets/images/Infografia ALA.png';

const App = (props) => {
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
	const [snackbarMessage] = useState('...');
    
    const {initializing, user} = useAuth();

    useUpdateDbUserLastPageRefreshOrLoggedInTime(user);
	
	// const handleServiceWorkerUpdate = (registration) => {
	// 	setIsSnackbarOpen(true);
	// 	setSnackbarMessage('Hay una actualización disponible. La obtendrás después de cerrar todas las ventanas de la App y volver a abrirla');
	// }
	
	// const handleServiceWorkerSuccess = (registration) => {
	// 	setIsSnackbarOpen(true);
	// 	setSnackbarMessage('Ahora puedes usar varias funcionalidades de esta App incluso sin internet');
	// }

	// serviceWorker.register({
	// 	onUpdate: handleServiceWorkerUpdate,
	// 	onSuccess: handleServiceWorkerSuccess
	// });

	useEffect(() => {
		cssScrollSnapPolyfill();
	}, []);

    const isUserFullyRegistered = false; // TODO

    useEffect(() => {
        if (user && !isUserFullyRegistered) {
            const pathsToRedirectToRegisterAdditionalUserDataIfLoggedInButNotFullyRegistered = ["/", "/iniciar-sesion"];
            if (pathsToRedirectToRegisterAdditionalUserDataIfLoggedInButNotFullyRegistered.indexOf(props.location.pathname) > -1) {
                try {
                    historyPush("/registrar-datos-adicionales-de-usuario", props);
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }, [props.location.pathname, !user]);

	const handleSnackbarClose = () => setIsSnackbarOpen(false);

    if (initializing) {
        return (
            <userContext.Provider value={{initializing, user}}>
                {/* <Layout noFooter={true}><Spinner/></Layout> */}
            </userContext.Provider>
        );
    }

	return (
        <userContext.Provider value={{initializing, user}}>
            {/* <Switch>
                <Route path='/iniciar-sesion' render={() => <Layout noFooter={true}><Login/></Layout>}/>
                <Route path='/cerrar-sesion' render={() => <Layout><Logout/></Layout>}/>
                
                <Route path='/registrar-datos-adicionales-de-usuario' render={() => <RegisterAdditionalUserDataContainer/>}/>
                <Route path='/registrar-datos-adicionales-de-usuario-2' render={() => <RegisterAdditionalUserDataContainer/>}/>
                <Route path='/registrar-datos-adicionales-de-usuario-3' render={() => <RegisterAdditionalUserDataContainer/>}/>
                <Route path='/carro-de-compras' render={() => <RegisterAdditionalUserDataContainer/>}/>
                <Route path='/elegir-medio-de-pago' render={() => <RegisterAdditionalUserDataContainer/>}/>
                <Route path='/comprobante-de-pago' render={() => <RegisterAdditionalUserDataContainer/>}/>
                <Route path='/la-orden-no-se-pudo-completar' render={() => <RegisterAdditionalUserDataContainer/>}/>

                <Route path='/inicio' render={() => <Layout><Home/></Layout>}/>
                <Route path='/ayuda' render={() => <RegisterAdditionalUserDataContainer/>}/>
                <Route path='/' exact render={() => <Layout><StepsContainer/></Layout>}/>
            </Switch> */}
            <img src={infography} alt="Infografía ALA. La nueva forma de estar seguro" style={{width: "100%"}}/>
            <SimpleSnackbar onClose={handleSnackbarClose} message={snackbarMessage} open={isSnackbarOpen}/>
        </userContext.Provider>
	);
};

export default withRouter(App);
